.youtubeContainer{
    width: 100%;
    margin-bottom: var(---spacing-medium);
  }
  
.youtubeContainer div{
    width: 100%;
    padding-top: 56.25%;
    position: relative;
}

.youtubeContainer div iframe{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
}