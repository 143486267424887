.markdownShowFlexContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}

.markdownContainer{
  font-size: var(---font-size-body);
  width: 80ch;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.markdownContainer h2{
  width: 100%;
  font-size: var(---font-size-large-x);
  color: var(---color-highlight-1);
  margin-top: var(---spacing-medium);
  margin-bottom: var(---spacing-small);
}

.markdownContainer h3{
  width: 100%;
  font-size: var(---font-size-large-m);
  color: var(---color-highlight-1);
  margin-top: var(---spacing-small);
  margin-bottom: var(---spacing-small);
}

.markdownContainer h4{
  width: 100%;
  font-size: var(---font-size-body);
  color: var(---color-highlight-2);
  margin-top: var(---spacing-tiny);
  margin-bottom: var(---spacing-tiny);
}

.markdownContainer p{
  width: 100%;
  font-size: var(---font-size-body);
  color: var(---color-text);
  margin-top: 0;
  margin-bottom: var(---spacing-medium);
  line-height: var(---line-height-body);
}

.markdownContainer q { 
  color: var(---color-highlight-2);
}

.markdownContainer img{
  max-width: 100%;
  max-height: 100vh;
  width: max-content;
  margin-top: 0;
  margin-bottom: var(---spacing-medium);
}

.markdownContainer > video{
  max-width: 100%;
  max-height: 100vh;
  margin-top: 0;
  margin-bottom: var(---spacing-medium);
}

.markdownContainer > div.restrictVideoHeightContainer{
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.markdownContainer > div.restrictVideoHeightContainer > div{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}

.markdownContainer > div.restrictVideoHeightContainer > div > video{
  height: 100%;
  width: 100%;
}

.markdownContainer ul{
  box-sizing: border-box;
  width: 100%;
  font-size: var(---font-size-body);
  color: var(---color-text);
  margin-top: 0;
  margin-bottom: var(---spacing-medium);
  line-height: var(---line-height-body);
}

.markdownContainer li{
  margin-bottom: var(---spacing-micro);
}

.markdownContainer table {
  border-collapse: collapse;
  color: var(---color-text);
  margin-bottom: var(---spacing-medium);
}

/* Boarder and lines */
.markdownContainer table, 
.markdownContainer th, 
.markdownContainer td{
  border: 1px solid;
}

/* Styles for the cells */
.markdownContainer th, 
.markdownContainer td{
  padding: var(---spacing-micro);
}

.markdownContainer hr{
  width: 100%;
  border-color: var(---color-text-2);
  margin: 0;
}

/* Gallery Styles */

/* To make the gallery less wide than the mobile viewport */
.image-gallery-thumbnails,
.image-gallery{
  max-width: calc(100vw - var(---spacing-medium) * 2);
}

.fullscreen .image-gallery-thumbnails{
  max-width: unset;
}

/* For Fullscreen, centering the gallery to the middle instead of appearing at the top */
.image-gallery{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Overides */
.markdownContainer .image-gallery-content img{
  margin-bottom: 0;
}


/* Breakpoints */
@media only screen and (min-width: 601px){

  .markdownContainer > div.restrictVideoHeightContainer{
    /* Forces video max height to be 16:9 for screen larger than 601px viewport */
    padding-top: 56.25%;
  }

}