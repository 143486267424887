/* Project Card */
.projectCard {
    display: flex;
    flex-direction: column;
    margin-bottom: var(---spacing-large);
}
  
.imageContainer{
    width: 100%;
}

.imageContainer div{
    width: 100%;
    padding-top: 57%;
    position: relative;
}

.imageContainer div img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
}
  
.title {
    color: var(---color-highlight-1);
    margin: var(---spacing-small) 0 var(---spacing-micro);
}

.year{
    margin: 0;
    margin-bottom: var(---spacing-micro);
    font-style: italic;
    color: var(---color-text-2);
}

.body{
    margin: 0;
    line-height: var(---line-height-body);
}