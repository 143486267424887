.footer{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.footer .linkedInIcon{
  margin-right: var(---spacing-small);
}