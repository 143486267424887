.projectList {
  display: grid;
  row-gap: var(---spacing-large);
  width: 100%;
  margin-bottom: var(---spacing-medium);
}

@media only screen and (min-width: 601px){

  .projectList {
    column-gap: var(---spacing-medium);
    grid-template-columns: 1fr 1fr;
  }

}

@media only screen and (min-width: 1025px){

  .projectList {
    grid-template-columns: 1fr 1fr 1fr;
  }

}